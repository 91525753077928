html{
    /* background-color: black; */
}

.profile-container {
    font-family:'Lucida Sans', Arial, sans-serif;
    margin: 0 auto 0 auto;
    width: 100%;
    height: 100vh;
    background-color: #000000;
    overflow: auto;
}

.profile-body-header {
    background-color: black;
    /* display: block; */
    /* font-style: italic; */
    color: white;
    /* width: 100%; */
    /* padding: 25px 10px 5px 10px; */
    /* overflow: hidden;
    margin-right: auto; */
    padding-top: 100px;
    padding-left: 40px;
    font-size: 22px;
    font-weight: 600;
}

.profile-body {
    width: 100%;
    padding: 29px 10px 5px 10px;
    overflow:hidden;
}

.delete-bet-btn{
    background-color: red;
    border-radius: 3px;
    border: none;
    color: white;
    padding: 8px 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    margin-right: 7px;
    margin-left: 7px;
}

/* .delete-bet-btn:hover{
    opacity: 80%;
    background-color: red;
    border-radius: 3px;
    border: none;
    color: white;
    padding: 8px 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    margin-right: 7px;
    margin-left: 7px;
    transition: all 0.3s ease 0s;
} */

.delete-bet-btn-hidden{
    /* visibility: hidden; */
    display: none;
}

.bets-nav-bar {
    background-color: #242424;
    padding-top: 30px;
    padding-right: 40px;
    padding-left: 40px;
    padding-bottom: 50px;
    background: black;
    /* display: flex;
    flex-direction: column; */
}

.bets-btn{
    background-color: #121212;
    border-radius: 5px;
    border: none;
    color: white;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    margin-right: 7px;
}

.bets-btn-selected{
    background-color: white;
    border-radius: 5px;
    border: none;
    color: black;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    margin-right: 7px;
}

.bets-btn:hover {
    background-color: white;
    border-radius: 5px;
    border: none;
    color: black;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    margin-right: 7px;
    transition: all 0.3s ease 0s;
}

.table-container{
    background-color: #242424;
    border: 1px solid black;
    padding: 25px;
    border-radius: 10px;
    margin-left: 40px;
    width: 95%;
}

.header-container{
    border: 1px solid black;
}

.bet-container{
    /* display: flex;
    justify-content: space-evenly; */
}

.inline{
    padding: 5px 50px 0px 50px;
    color: white;
    font-size: 20px;
    text-align: center;
    background-color: #242424;
    /* width: 100%; */
    text-decoration: none;
    border: none;
}
.inline-created-at{
    color: white;
    font-size: 20px;
    text-align: center;
    background-color: #242424;
    /* width: 100%; */
    text-decoration: none;
    border: none;
}

td a {
    /* padding: 5px 50px 0px 0px; */
    color: white;
    font-size: 15px;
    /* text-align: center; */
    background-color: #53d337;
    /* width: 100%; */
    text-decoration: none;
    border: none;
}

.game-log{
    font-size: 15px;
    /* border: none; */
    background-color: #53d337;
    border-radius: 3px;
    border: none;
    color: white;
    padding: 8px 13px;
    text-align: center;
    text-decoration: none;
}

.game-log:hover{
    /* text-decoration: underline; */
}

.status{
    padding: 5px 50px 0px 50px;
    color: white;
    font-size: 20px;
    text-align: center;
}

.status-lost{
    padding: 5px 50px 0px 50px;
    color: red;
    font-size: 20px;
    text-align: center;
}

.status-won{
    padding: 5px 50px 0px 50px;
    color: lightgreen;
    font-size: 20px;
    text-align: center;
}

.status-in-progress{
    padding: 5px 50px 0px 50px;
    color: yellow;
    font-size: 20px;
    text-align: center;
}

.inline-headers{
    padding: 0px 50px 10px 50px;
    color:white;
    font-weight: 600;
    font-size: 24px;
    /* border-bottom: solid 1px white; */
}


#myChart{
  height: 100%;
  width: 100%;
}

.ChartContainer{
    z-index: 3;
    height: 80%;
    width: 80%;
    margin: 90px;
    margin-left: 140px;
}