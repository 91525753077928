.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    color: #333;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;
    padding: 10px 0; /* Adjust vertical padding */
}

.contact {
    font-size: 18px;
    margin-right: 20px; /* Add some spacing to the right of the text */
}

.social-icons a {
    display: inline-block;
    margin: 0 10px;
    text-decoration: none;
    color: white;
    transition: transform 0.2s;
}

.social-icons a:hover {
    transform: translateY(-3px);
}

.social-icons img {
    width: 25px;
    height: 25px;
}