.signup-form-container {
    margin-top: 200px;
    font-family:'Lucida Sans', Arial, sans-serif;
    display: flex;
    justify-content: center;
    height: 100%;
}

.form-container-signup-2 {
    display: flex;
    flex-direction: column;
}

.signup-header {
    text-align: center;
    color: #535254;
    font-size: 36px;
    font-weight:bold;
    margin-bottom: 20px;
}

.signup-form-fields {
    border: 1px solid #D8D7D9;
    border-radius: 2px;
    color: #2B2A2C;
    margin-top: 5px; 
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.signup-container-message {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.signup-message {
    display: inline;
    padding-top: 25px;
}

.signup-page-login-btn {
    font-family:'Lucida Sans', Arial, sans-serif;
    margin-left: 10px;
    margin-top: 15px;
    padding: 8px 15px 8px 15px;
    border: 1px solid #000000;
    border-radius: 3px;
}

/* .signup-page-login-btn:hover {
    font-family:'Lucida Sans', Arial, sans-serif;
    background-color: black;
    color:white;
    margin-left: 10px;
    margin-top: 15px;
    padding: 8px 15px 8px 15px;
    border: 1px solid #000000;
    border-radius: 3px;
    transition: all 0.3s ease 0s;
} */