nav {
    background-color: #000000;
}

.nav-bar-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* color: white; */
    color: black;
    padding: 10px 12px 15px 12px;
    position: fixed; 
    top: 0; 
    width: 100%; 
    z-index: 4;
}

.profile-btn {
    font-family:'Lucida Sans', Arial, sans-serif;
    /* background-color: #000000; */
}

.logout-btn {
    font-family:'Lucida Sans', Arial, sans-serif;
    /* background-color: #000000; */
}

.signup-btn {
    font-family:'Lucida Sans', Arial, sans-serif;
    /* background-color: #000000; */
    margin-right: 30px;  
    margin-top: 8px;
    padding: 5px 15px 5px 15px;
    /* border: 1px solid white; */
    border: 1px solid #000000;
    border-radius: 5px;
}

.login-btn {
    font-family:'Lucida Sans', Arial, sans-serif;
    /* background-color: #000000; */
    margin-right: 30px;
    margin-top: 8px;
    padding: 5px 15px 5px 15px;
    /* border: 1px solid white; */
    border: 1px solid #000000;
    border-radius: 5px;
}

.logo {
    background-color: black;
    margin-right: auto;
    padding-left: 10px;
    width: auto;
    height: 50px;
}

.profile-btn {
    font-family:'Lucida Sans', Arial, sans-serif;
    /* background-color: #000000; */
    margin-right: 20px;  
    margin-top: 8px;
    padding: 5px 15px 5px 15px;
    /* border: 1px solid white; */
    border: 1px solid #000000;
    border-radius: 5px;
}

.logout-btn {
    font-family:'Lucida Sans', Arial, sans-serif;
    /* background-color: #000000; */
    margin-right: 35px;
    margin-top: 8px;
    padding: 5px 15px 5px 15px;
    /* border: 1px solid white; */
    border: 1px solid #000000;
    border-radius: 5px;
}

.demo-btn {
    font-family:'Lucida Sans', Arial, sans-serif;
    /* background-color: #000000; */
    margin-right: 35px;
    margin-top: 8px;
    padding: 5px 15px 5px 15px;
    /* border: 1px solid white; */
    border: 1px solid #000000;
    border-radius: 5px;
}

.currency-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* color: white; */
    color: black;
    padding: 10px 12px 15px 12px;
}

.currency-amt {
    font-family:'Lucida Sans', Arial, sans-serif;
    background-color: black;
    color: greenyellow;
    margin-top: 10px;
    padding: 2px 20px 5px 0px;
    font-size: 18px;
}

.currency-header {
    font-family:'Lucida Sans', Arial, sans-serif;
    background-color: black;
    color: white;
    padding: 5px 5px 0px 15px;
    font-size: 18px;
}

.currency-deposit {
    font-family:'Lucida Sans', Arial, sans-serif;
    background-color: #52D337;
    margin-right: 30px;
    margin-top: 8px;
    padding: 5px 15px 5px 15px;
    /* border: 1px solid white; */
    border: 1px solid #52D337;
    border-radius: 3px;
}