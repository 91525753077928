.form-container {
    margin-top: 80px;
    font-family:'Lucida Sans', Arial, sans-serif;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;   
    padding-left: 10px;
    padding-right: 10px;

}

.form-container-login-2 {
    display: flex;
    flex-direction: column;
    padding-top: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-inline: 30px;
    border-radius: 1rem;
    box-shadow: 0px 0px 8px 4px rgba(0,0,0,0.39);
    -webkit-box-shadow: 0px 0px 8px 4px rgba(0,0,0,0.39);
    -moz-box-shadow: 0px 0px 8px 4px rgba(0,0,0,0.39);
}

.login-header {
    text-align: center;
    color: #535254;
    font-size: 36px;
    font-weight:bold;
    margin-bottom: 20px;
    padding-top: 30px;
 

}

.login-form-fields {
    border: 1px solid #D8D7D9;
    border-radius: 2px;
    color: #2B2A2C;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.submit-btn {  
    background-color: #53d337;
    border: 1px solid #53d337;
    border-radius: 4px;
    text-align: center;
    font-size: 23px;
    font-weight:bold;
    margin-top: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 83px;
    padding-right: 80px;
    cursor: pointer;
    transition: all 0.3s ease 0s;
}

.submit-btn:hover {
    
}

.login-container-message {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.login-message {
    display: inline;
    padding-top: 25px;
}

.login-page-signup-btn {
    font-family:'Lucida Sans', Arial, sans-serif;
    margin-left: 10px;
    margin-top: 15px;
    padding: 8px 15px 8px 15px;
    border: 1px solid #000000;
    border-radius: 3px;
}

.warning-text {
    inline-size: 400px;
    margin-top: 25px;
    font-size: 13px;
    text-align: center;
    flex-wrap: wrap;
    color: #2B2A2C;
    opacity: 80%;
}

.line {
    /* width: 400px; */
    height: 47px;
    border-bottom: 1px solid #C4C4C4;
}

.login-error-messages {
    text-align: center;
    color: tomato;
}