/* Dropup Button */
.dropbtn {
  background-color: #3498DB;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  margin-right: 5px;
  margin-bottom: 10px;
}

.dropbtn-github {
  background-color: black;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  margin-right: 5px;
  margin-bottom: 10px;
}


/* The container <div> - needed to position the dropup content */
.dropup {
  position: relative;
  display: inline-block;
  display: flex;
  justify-content: center;
}

/* Dropup content (Hidden by Default) */
.dropup-content {
  display: none;
  position: absolute;
  bottom: 60px;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropup */
.dropup-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropup links on hover */
.dropup-content a:hover {background-color: #ddd}

/* Show the dropup menu on hover */
.dropup:hover .dropup-content {
  display: block;
}

/* Change the background color of the dropup button when the dropup content is shown */
.dropup:hover .dropbtn {
  background-color: #2980B9;
}

.footer-container{
    font-family:'Lucida Sans', Arial, sans-serif;
    display: flex;
    justify-content: center;
}

.main-footer{
    padding-bottom: 10px;
}